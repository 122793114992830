import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function GiveMeOneReason() {
  return (
    <Layout>
      <Helmet title="Give Me One Reason | Tracy Chapman" />

      <h1>Give Me One Reason</h1>

      <h2>Tracy Chapman</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Chorus>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>Said I don't want to leave you lonely</p>
          <p>You got to make me change my mind</p>
        </Chorus>
        <Verse>
          <p>Baby I got your number</p>
          <p>Oh and I know that you got mine</p>
          <p>You know that I called you</p>
          <p>I called you many times</p>
          <p>You can call me baby</p>
          <p>You can call me anytime, you got to call me</p>
        </Verse>
        <Chorus>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>(You can see me turnin')</p>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>(You can see me turnin')</p>
          <p>Said I don't want to leave you lonely</p>
          <p>You got to make me change my mind</p>
        </Chorus>
        <Verse>
          <p>I don't want no one to squeeze me</p>
          <p>They might take away my life</p>
          <p>I don't want no one to squeeze me</p>
          <p>They might take away my life</p>
          <p>I just want someone to hold me</p>
          <p>Oh and rock me through the night</p>
        </Verse>
        <Verse>
          <p>This youthful heart can love you</p>
          <p>Yes and give you what you need</p>
          <p>I said this youthful heart can love you</p>
          <p>Yes and give you what you need</p>
          <p>But I'm too old to go chasin' you around</p>
          <p>Wastin' my precious energy</p>
        </Verse>
        <Chorus>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>(You can see me turnin')</p>
          <p>Give me one reason to stay here</p>
          <p>And I'll turn right back around</p>
          <p>(You can see me turnin')</p>
          <p>Said I don't want to leave you lonely</p>
          <p>You got to make me change my mind</p>
        </Chorus>
        <Verse>
          <p>Baby just give me one reason</p>
          <p>Oh give me just one reason why</p>
          <p>Baby just give me one reason</p>
          <p>Oh give me just one reason why</p>
          <p>Said I told you that I loved you</p>
          <p>And there ain't no more to say</p>
        </Verse>
      </Columns>
    </Layout>
  );
}
